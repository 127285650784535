import React from 'react'

const HeadlineCards = () => {
  return (
    <div className='max-w-[1640px] mx-auto p-4 py-12 grid md:grid-cols-3 gap-6'>
        {/* cards */}
        <div className='rounded-xl relative'>
            <div className='absolute w-full h-full bg-black/50 text-white'>
                <p className='font-bold text-2xl px-2 pt-4'>Sun's Out, BOGO' Out</p>
                <p className='px-2'>Through 8/26</p>
                <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Order Now</button>
            </div>
            <img
            className='max-h-[200px] md:max-h-[300px] w-full object-cover rounded-xl'
            src="https://images.pexels.com/photos/1099680/pexels-photo-1099680.jpeg?auto=compress&cs=tinysrgb&w=600"
             alt="" />
        </div>
        {/* cards */}
        <div className='rounded-xl relative'>
            <div className='absolute w-full h-full bg-black/50 text-white'>
                <p className='font-bold text-2xl px-2 pt-4'>New Restaurants</p>
                <p className='px-2'>Added Daily</p>
                <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Order Now</button>
            </div>
            <img
            className='max-h-[200px] md:max-h-[300px] w-full object-cover rounded-xl'
            src="https://images.pexels.com/photos/671956/pexels-photo-671956.jpeg?auto=compress&cs=tinysrgb&w=600"
             alt="" />
        </div>
        {/* cards */}
        <div className='rounded-xl relative'>
            <div className='absolute w-full h-full bg-black/50 text-white'>
                <p className='font-bold text-2xl px-2 pt-4'>We Deliver Desserts too</p>
                <p className='px-2'>Tasty Treats</p>
                <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Order Now</button>
            </div>
            <img
            className='max-h-[200px] md:max-h-[300px] w-full object-cover rounded-xl'
            src='https://images.pexels.com/photos/6947255/pexels-photo-6947255.jpeg?auto=compress&cs=tinysrgb&w=300'
            alt="" />
        </div>
    </div>
  )
}

export default HeadlineCards